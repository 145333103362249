import { render, staticRenderFns } from "./UserinfoPage.vue?vue&type=template&id=28ffb390&scoped=true&"
import script from "./UserinfoPage.vue?vue&type=script&lang=js&"
export * from "./UserinfoPage.vue?vue&type=script&lang=js&"
import style0 from "./UserinfoPage.vue?vue&type=style&index=0&id=28ffb390&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ffb390",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/frontend/ftpay-ui-merchant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28ffb390')) {
      api.createRecord('28ffb390', component.options)
    } else {
      api.reload('28ffb390', component.options)
    }
    module.hot.accept("./UserinfoPage.vue?vue&type=template&id=28ffb390&scoped=true&", function () {
      api.rerender('28ffb390', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/current/UserinfoPage.vue"
export default component.exports